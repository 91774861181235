import { configApiRef, useApi } from '@backstage/core-plugin-api';
import {
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Tooltip,
  Typography,
  createStyles,
  makeStyles,
  useTheme,
  withStyles,
} from '@material-ui/core';
import React, { Ref, useEffect, useState } from 'react';
import { ControllerRenderProps } from 'react-hook-form';
import { useAsync } from 'react-use';
import { FieldValidation } from '@rjsf/utils';
import { Alert } from '@material-ui/lab';
import InfoIcon from '@material-ui/icons/Info';
import { adServiceApiRef } from '../../../services';

const useStyles = makeStyles(() =>
  createStyles({
    center: {
      display: 'flex',
      justifyContent: 'center',
    },
    radioGroup: {
      flexDirection: 'row',
    },
  }),
);

type ClusterType = {
  name: string;
  displayName: string;
  production: boolean;
  deployable: boolean;
  allowedAadGroups?: { name: string }[];
} & Record<string, unknown>;

type AzureGroup = {
  groupName: string;
  groupId: string;
};

export enum DataClassification {
  PCI = 'pci',
  NON_PCI = 'non-pci',
}

const NoPaddingTooltip = withStyles({
  tooltip: {
    padding: 0,
    margin: 5,
  },
})(Tooltip);

export const DataClassificationGroup = React.forwardRef((props: ControllerRenderProps, ref: Ref<unknown>) => {
  const { onChange, ...other } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [pciOption, setPciOption] = useState<string>(DataClassification.NON_PCI);

  const handlePciOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPciOption(event.target.value);
    onChange(event.target.value);
  };

  return (
    <>
      <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
        <Typography aria-label="pci" style={{ color: theme.palette.text.secondary, padding: '0px 10px' }}>
          PCI:
        </Typography>
        <RadioGroup
          className={classes.radioGroup}
          {...other}
          onChange={handlePciOptionChange}
          ref={ref}
          aria-label="radiogroup-btn-pci"
          name="options"
          value={pciOption}
        >
          <FormControlLabel value={DataClassification.PCI} aria-label="radio-btn-pci" control={<Radio />} label="PCI" />
          <FormControlLabel
            value={DataClassification.NON_PCI}
            aria-label="radio-btn-non-pci"
            control={<Radio />}
            label="Non-PCI"
          />
        </RadioGroup>
        <Grid md={3} item>
          <NoPaddingTooltip
            title={<Alert severity="info">PCI clusters are compliant with Payment Card Industry standards</Alert>}
          >
            <IconButton color="primary" size="small" aria-label="info-button">
              <InfoIcon />
            </IconButton>
          </NoPaddingTooltip>
        </Grid>
      </Grid>
    </>
  );
});

export const Cluster = React.forwardRef((props: ControllerRenderProps & { pci: string }, ref: Ref<unknown>) => {
  const { pci, ...other } = props;

  const config = useApi(configApiRef);
  const aadGroups = useApi(adServiceApiRef);
  const [clusters, setClusters] = useState<ClusterType[]>([]);
  const [userAadGroups, setUsersAdGroups] = useState<AzureGroup[]>([]);

  const filterClusters = (clustersList: ClusterType[]) =>
    clustersList
      .filter(cluster =>
        cluster.allowedAadGroups
          ? cluster.allowedAadGroups.some(group =>
              userAadGroups.some((userGroup: AzureGroup) => userGroup.groupName === group.name),
            )
          : true,
      )
      .filter(cluster => (cluster.pci ? pci === DataClassification.PCI : pci === DataClassification.NON_PCI))
      .filter(cluster => cluster.deployable);

  useAsync(async () => {
    const resp = await aadGroups.getUsersGroupMembershipAzureAD();
    setUsersAdGroups(resp?.outputGroups);
  });

  useEffect(() => {
    const kubernetesConfigArray = config.getConfigArray('kubernetes.clusterLocatorMethods')[0];
    setClusters(filterClusters((kubernetesConfigArray as any).data.clusters));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config, pci, userAadGroups]);

  return (
    <FormControl variant="outlined" style={{ width: '100%' }} required>
      <InputLabel id="cluster-label">Cluster</InputLabel>
      <Select
        {...other}
        ref={ref}
        id="cluster-select"
        inputProps={{ 'aria-label': 'cluster-dropdown' }}
        label="Cluster"
      >
        {clusters.length === 0 ? (
          <MenuItem value="">
            <em>No clusters available</em>
          </MenuItem>
        ) : (
          clusters.map((cluster: ClusterType) => (
            <MenuItem
              key={cluster.name}
              value={cluster.name}
              aria-label={`cluster-option-${cluster.name.toLowerCase()}`}
            >
              {cluster.displayName}
            </MenuItem>
          ))
        )}
      </Select>
    </FormControl>
  );
});

export const clusterValidation = (value: string, validation: FieldValidation) => {
  if (!value) {
    validation.addError('Please select a cluster');
  }
};
