import axios, { AxiosResponse } from 'axios';
import { createApiRef, IdentityApi } from '@backstage/core-plugin-api';
import { OAuth2AAPingSSOApi } from '@runway/devkit';

type CreateVmOnPremReq = {
  serverRole: string;
  envTag: string;
  vmCount: number;
  serverSize: string;
  appCriticalityTag: string;
  serverType: string;
  appShortName: string;
  verticalName: string;
  dcLocation: string;
};

type CreateVmOnPremResponse = {
  deploymentName: string;
};

export interface CreateVmOnPremApi {
  createVmOnPrem(req: CreateVmOnPremReq): Promise<AxiosResponse<CreateVmOnPremResponse>>;
  createDXCVm(req: any): Promise<AxiosResponse>;
}

export const CreateVmOnPremApiRef = createApiRef<CreateVmOnPremApi>({
  id: 'plugin.infrastructure.vra-build.service',
});

export default class CreateVmOnPrem implements CreateVmOnPremApi {
  constructor(
    private vmUrl: string,
    private baseBackendUrl: string,
    private identityApi: IdentityApi,
    private oAuthApi: OAuth2AAPingSSOApi,
  ) {}

  async createVmOnPrem(req: CreateVmOnPremReq) {
    const token = await this.oAuthApi.getAccessToken();
    let createResponse: AxiosResponse<CreateVmOnPremResponse>;
    try {
      createResponse = await axios.post<CreateVmOnPremResponse>(
        `${this.vmUrl}/manage/vm`,
        { ...req },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
    } catch (e: string | any) {
      if (e.response) {
        const message = `Status code: ${e.response.status}
Status message: ${e.response.statusText}
Failure Message: ${e.response.data.detail}`;
        throw new Error(message);
      } else {
        throw e;
      }
    }
    return createResponse;
  }

  async createDXCVm(req: any) {
    const { token } = await this.identityApi.getCredentials();

    let createResponse: AxiosResponse<CreateVmOnPremResponse>;
    try {
      createResponse = await axios.post<CreateVmOnPremResponse>(
        `${this.baseBackendUrl}/api/infrastructure/vmManagement/requestDXCVM`,
        { ...req },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
    } catch (e: string | any) {
      if (e.response) {
        const message = `Status code: ${e.response.status}
Status message: ${e.response.statusText}
Failure Message: ${e.response.data.detail}`;
        throw new Error(message);
      } else {
        throw e;
      }
    }
    return createResponse;
  }
}
