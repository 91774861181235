import React from 'react';
import { Grid } from '@material-ui/core';
import { RowProps } from '../types';

/**
 * Use this component to encapsulate 1 or more children components in a single row.
 * @param spacing optional spacing between items
 * @param children children components
 */
export function FormRow({ children, spacing = 2 }: RowProps) {
  return (
    <Grid aria-label="form-row" container spacing={spacing} direction="row">
      {children}
    </Grid>
  );
}
