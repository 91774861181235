import React from 'react';
import { FieldValidation } from '@rjsf/utils';
import { pick } from 'lodash';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import { SquadIdName } from '../../services';
import { SquadInfo } from '../../components';

export const SquadPicker = ({ onChange, formData }: FieldExtensionComponentProps<SquadIdName>) => {
  return (
    <SquadInfo
      onChange={(value: SquadIdName) => {
        const out = pick(value, ['name', 'id']);
        onChange(out || null);
      }}
      initialValue={formData}
      required
    />
  );
};

export const squadPickerValidation = (value: SquadIdName, validation: FieldValidation) => {
  if (value === (null || undefined)) {
    validation.addError('Please select a squad');
  }
};
