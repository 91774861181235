import axios from 'axios';
import { createApiRef } from '@backstage/core-plugin-api';

export enum JobState {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  IN_PROGRESS = 'BUILDING',
  WAITING = 'Not found',
}

export interface GhecInviteFormApi {
  sendEmailInvite: (email: string, require2FA: boolean) => Promise<JobState>;
}

export const ghecInviteFormApiRef = createApiRef<GhecInviteFormApi>({
  id: 'plugin.create-project.ghec.service',
});

export default class GhecInvite implements GhecInviteFormApi {
  constructor(private ghecServiceUrl: string) {}

  async sendEmailInvite(email: string, require2FA: boolean): Promise<JobState> {
    try {
      const result = await axios.post(`${this.ghecServiceUrl}/api/invite?email=${email}&require2FA=${require2FA}`);
      if (result.data === 'Sent Invitation') {
        return JobState.SUCCESS;
      }
      return JobState.FAILURE;
    } catch (e: any) {
      return JobState.FAILURE;
    }
  }
}
