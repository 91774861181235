import { Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useContext } from 'react';
import FormContext from '../FormContext';
import { BaseProps } from '../types';
import { componentGridStyle } from '../styles';

/**
 * Use this component to display an error alert in your form. It will display the error captured from the submit button's `onSubmit` request.
 * @param style optional style object
 * @param size optional size of the component
 */
export function FormErrorAlert({ style, size = 12 }: BaseProps) {
  const { state } = useContext(FormContext);
  if (!(state.status === 'error')) return <></>;
  return (
    <Grid item xs={12} sm={12} md={size} style={componentGridStyle}>
      <Alert severity="error" aria-label="error-message" style={{ ...style }}>
        {state.message}
      </Alert>
    </Grid>
  );
}
