import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  Link,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import axios from 'axios';
import Alert from '@material-ui/lab/Alert';
import { Autocomplete } from '@material-ui/lab';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { CmdbAppShortName } from '../CmdbAppShortName/CmdbAppShortName';
import { ClusterSelector } from '../ClusterSelector/ClusterSelector';
import { aaPingSSOAuthApiRef } from '../../services';
import { useAsync } from 'react-use';
import { useApi, configApiRef, identityApiRef, ProfileInfo } from '@backstage/core-plugin-api';
import { ActiveDirectoryGroupsDialog } from './ActiveDirectoryGroupsDialog';
import { BucketId, ClientId, PluginId } from '@runway/devkit-common';
import { useTrackedRequest } from '../../hooks';

type ClusterServiceResponse = {
  msg: string;
  project_name: string;
  namespace_name: string;
  project_id: string;
  cluster_id: string;
  owner: string;
};

const environments = ['Dev', 'Test', 'QA', 'Stage', 'NonProd', 'Prod'];

export const defaultValues = {
  cluster: '',
  project: '',
  namespace: '',
  aadGroup: '',
  resourceSize: '',
  environment: '',
};

export const schema = yup.object({
  cluster: yup.string().required(),
  project: yup.string().required(),
  namespace: yup
    .string()
    .required()
    .matches(/^[a-z0-9-]*$/g, 'Must not contain uppercase letters or underscores'),
  aadGroup: yup.string().required(),
  resourceSize: yup.string().required(),
  environment: yup.string().oneOf(environments).required(),
});

type RancherProjectForm = yup.InferType<typeof schema>;

const useStyles = makeStyles(theme => ({
  formGroup: {
    margin: theme.spacing(2),
    minWidth: 195,
  },
  button: {
    marginRight: theme.spacing(1),
  },
  inputFields: {
    width: 500,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const createProjectAndNamespace = async (
  baseUrl: string,
  formValues: RancherProjectForm,
  token: string | undefined,
) => {
  const resp = await axios.post<ClusterServiceResponse>(
    `${baseUrl}/projects`,
    {
      cluster_name: formValues.cluster,
      project_name: formValues.project,
      namespace_name: formValues.namespace,
      aad_group_name: formValues.aadGroup,
      resource_size: formValues.resourceSize,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return resp.data;
};

const useAadGroups = (
  baseUrl: string,
  employeeId: string | undefined,
  token: string | undefined,
  newAADGroup: string | undefined,
) => {
  const { value } = useAsync(async () => {
    let aadGroupList;
    try {
      if (!employeeId) return undefined;
      const resp: any = await axios.get(
        `${baseUrl}/api/infrastructure/azure/userActiveDirectoryGroups?employeeId=${employeeId.replace(/^00/, '')}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      aadGroupList = resp.data as string[];
      if (newAADGroup && !aadGroupList.includes(newAADGroup)) aadGroupList.push(newAADGroup);
      return aadGroupList.sort();
    } catch (error: any) {
      throw new Error('Cannot get AAD Group Names');
    }
  }, [employeeId, newAADGroup]);
  return value;
};

const ErrorPage = ({ message, onReset }: { message: string; onReset: () => void }) => (
  <>
    <Alert severity="error">{message}</Alert>
    <Button onClick={() => onReset()}>Go Back</Button>
  </>
);

const SuccessPage = ({ message, onReset }: { message: string; onReset: () => void }) => (
  <>
    <Alert severity="success">{message}</Alert>
    <h3>What is a namespace?</h3>
    <p style={{ maxWidth: '50%', fontSize: '1.1em' }}>
      In Kubernetes, namespaces provide a mechanism for isolating groups of resources within a single cluster.
      Namespaces are intended for use in environments with many users spread across multiple teams, or projects.
    </p>
    <h3>What can you do with a namespace in Runway?</h3>
    <ul>
      <li>
        <Link href="https://developer.aa.com/create">Use it to create an app using a software template</Link>
      </li>
      <li>
        <Link href="https://developer.aa.com/catalog">Use it within the deployment options for an existing app</Link>
      </li>
      <li>
        <Link href="https://developer.aa.com/migration-guidance">Use it in your migration to the cloud</Link>
      </li>
      <li>
        <Link href="https://developer.aa.com/docs/default/component/runway/getting-started/userguides/create-a-app/">
          You can find additional Runway documentation on clusters and creating an apps here
        </Link>
      </li>
    </ul>
    <Button onClick={() => onReset()} variant="contained" color="primary">
      Create another Project and Namespace
    </Button>
  </>
);

const SubmittedCreateForm = ({
  formValues,
  onResetForm,
}: {
  formValues: RancherProjectForm;
  onResetForm: () => void;
}) => {
  const configApi = useApi(configApiRef);
  const ssoApi = useApi(aaPingSSOAuthApiRef);

  const { tracker } = useTrackedRequest({
    bucket_id: BucketId.USE_METRIC,
    client_id: ClientId.RUNWAY_FRONTEND,
    pluginId: PluginId.MANAGE_RANCHER_ADD_NAMESPACE.id,
  });

  const { loading, value, error } = useAsync(async () => {
    try {
      const identity = await ssoApi.getAAProfile({
        optional: true,
        instantPopup: true,
      });
      const ssoToken = await ssoApi.getAccessToken();

      const metadata = {
        userEmployeeId: identity?.aaId ? identity.aaId : '-1',
        clusterName: formValues.cluster,
        appShortName: formValues.project,
        groupName: formValues.aadGroup,
        resourceSize: formValues.resourceSize,
      };

      return await tracker(async () => {
        return await createProjectAndNamespace(configApi.getString('clusterService.baseUrl'), formValues, ssoToken);
      }, metadata);
    } catch (err: any) {
      throw new Error(err.response?.data?.detail || err.message);
    }
  });

  return (
    <>
      {loading && <CircularProgress />}
      {error && <ErrorPage message={error.message} onReset={onResetForm} />}
      {value && <SuccessPage message={value?.msg ?? ''} onReset={onResetForm} />}
    </>
  );
};

const CreateForm = ({
  employeeId,
  token,
  onSubmit,
}: {
  employeeId: string | undefined;
  token: string | undefined;
  onSubmit: (v: RancherProjectForm) => void;
}) => {
  const classes = useStyles();
  const [ActiveDirectoryGroupsDialogOpen, setActiveDirectoryGroupsDialogOpen] = useState(false);
  const [createdAADGroup, setCreatedAADGroup] = useState('');
  const configApi = useApi(configApiRef);

  const aadGroups = useAadGroups(configApi.getString('backend.baseUrl'), employeeId, token, createdAADGroup);
  const {
    register,
    getValues,
    handleSubmit,
    control,
    formState: { isValid, errors },
  } = useForm<RancherProjectForm>({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(schema),
  });
  const finalizeFormValues = (values: RancherProjectForm): void => {
    onSubmit({
      ...values,
      namespace: `${values.namespace}-${values.environment.toLocaleLowerCase()}`,
    });
  };

  const handleSuccess = (req: { activeDirectoryGroupName: string }) => setCreatedAADGroup(req.activeDirectoryGroupName);

  return (
    <form onSubmit={handleSubmit(finalizeFormValues)}>
      <FormControl variant="outlined" className={classes.formGroup}>
        <Alert severity="info" style={{ marginBottom: '30px' }}>
          <strong>
            Users can utilize this namespace in the Create App plugin of Runway. The full Kubernetes dashboard is
            available through Rancher if needed at{' '}
            <Link
              href="/docs/#/getting-started/userguides/created-apps?id=apps-created-with-rancher-namespace"
              underline="always"
            >
              this link.
            </Link>
          </strong>
        </Alert>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Controller
            name="cluster"
            control={control}
            render={({ field: { onChange } }) => <ClusterSelector onClick={onChange} includeProd />}
          />
        </div>
      </FormControl>
      <div className={classes.formGroup}>
        <Controller
          name="project"
          control={control}
          render={({ field: { onChange } }) => (
            <CmdbAppShortName onChange={value => onChange(value.shortName)} key="archerShortName" getOwnApps />
          )}
        />
        <p style={{ width: 300, fontSize: '0.8em' }}>
          The selected Archer Shortname will be used for the Rancher project. Future iterations will add more options.
        </p>
      </div>
      <TextField
        error={Boolean(errors.namespace)}
        className={classes.formGroup}
        id="namespace"
        label="Namespace Name"
        helperText={Boolean(errors.namespace) ? errors.namespace?.message : 'Name of Namespace Created in Rancher'}
        style={{ width: '300px' }}
        required
        {...register('namespace')}
      />
      <div>
        <FormControl variant="outlined" className={classes.formGroup} required>
          <InputLabel id="namespace-environment">Environment</InputLabel>
          <Select
            labelId="namespace-environment"
            id="namespace-environment-select"
            aria-label="Namespace Environment"
            label="Environment"
            {...register('environment')}
            defaultValue={defaultValues.environment}
          >
            {environments.map(value => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
          <p aria-label="Environment Description" style={{ width: 300, fontSize: '0.8em', display: 'block' }}>
            The environment of your namespace. This option will be appended to your namespace name.
          </p>
          <Typography
            aria-label="Namespace Name Preview"
            variant="body2"
            style={{ width: 300, fontSize: '0.8em', display: 'block' }}
          >
            Full namespace name:{' '}
            {getValues().namespace && getValues().environment && (
              <strong>
                {getValues().namespace}-{getValues().environment.toLocaleLowerCase()}
              </strong>
            )}
          </Typography>
        </FormControl>
      </div>
      <div className={classes.formGroup}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Controller
            name="aadGroup"
            control={control}
            render={({ field: { onChange }, fieldState: { error } }) => (
              <Autocomplete
                id="aadGroup"
                loading={!aadGroups}
                loadingText="Loading AD Groups"
                options={aadGroups ?? []}
                autoComplete
                freeSolo
                includeInputInList
                filterSelectedOptions
                clearOnBlur
                onChange={(_, groupName) => onChange(groupName ?? '')}
                style={{ width: 400 }}
                renderInput={params => (
                  <TextField required {...params} label="Azure AD Group Name" variant="outlined" error={!!error} />
                )}
              />
            )}
          />
          {!aadGroups && <CircularProgress style={{ marginLeft: 10 }} />}
          <div className={classes.formGroup}>
            <Button
              variant="contained"
              color="primary"
              disabled={getValues().aadGroup !== ''}
              onClick={() => setActiveDirectoryGroupsDialogOpen(true)}
            >
              Create AADGroupName
            </Button>
            <Typography variant="body2" style={{ width: 300, fontSize: '0.8em', display: 'block' }}>
              <strong>Create Azure AD Group if don't have one already.</strong>
            </Typography>
            <ActiveDirectoryGroupsDialog
              isOpen={ActiveDirectoryGroupsDialogOpen}
              close={() => setActiveDirectoryGroupsDialogOpen(false)}
              onSuccess={handleSuccess}
            />
          </div>
        </div>
        <Typography variant="body2" style={{ width: 300, fontSize: '0.8em', display: 'block' }}>
          The displayed Azure Active Directory (AAD) Groups are based on the current user's memberships.
        </Typography>
        <Typography variant="body2" style={{ width: 300, fontSize: '0.8em', display: 'block' }}>
          <strong>
            The AAD group must already have members, not just owners. Runway currently does not add members.
          </strong>
        </Typography>
      </div>
      <FormControl variant="outlined" className={classes.formGroup} required>
        <InputLabel id="resource-tshirt-sizing-label">Resource Size</InputLabel>
        <Select
          labelId="resource-tshirt-sizing-label"
          id="resource-tshirt-sizing"
          label="Resource Size"
          {...register('resourceSize')}
          defaultValue={defaultValues.resourceSize}
        >
          {configApi.getConfigArray('clusterService.rancherProjectSize').map(size => (
            <MenuItem key={size.getString('name')} value={size.getString('name')}>
              {size.getString('name')} (cpu-{size.getString('cpu')} & mem-
              {size.getString('mem')})
            </MenuItem>
          ))}
        </Select>
        <p style={{ width: 300, fontSize: '0.8em', display: 'block' }}>
          If you require a custom size for your namespace, please open a support ticket in{' '}
          <Link href="https://americanairlines.slack.com/archives/C0112F1JKNG">#runway</Link>
        </p>
      </FormControl>
      <div className={classes.formGroup}>
        <Button type="submit" variant="contained" color="primary" disabled={!isValid}>
          Create
        </Button>
      </div>
    </form>
  );
};

export const CreateRancherProjectAndNamespace = ({
  setNamespace,
  setSelectedCluster,
  availableNamespaces,
  setAvailableNamespaces,
  onChange,
}: {
  setNamespace?: Dispatch<SetStateAction<string>>;
  setSelectedCluster?: Dispatch<SetStateAction<string | null>>;
  availableNamespaces?: string[];
  setAvailableNamespaces?: Dispatch<SetStateAction<string[]>>;
  onChange?: (v: string) => void;
}) => {
  const [formValues, setFormValues] = useState<RancherProjectForm | null>(null);
  const identityApi = useApi(identityApiRef);

  const { value } = useAsync(async () => {
    const employeeProfile = (await identityApi.getProfileInfo()) as ProfileInfo & { aaId?: string };
    const { token } = await identityApi.getCredentials();
    return { employeeId: employeeProfile.aaId, token };
  });

  return formValues === null ? (
    <CreateForm
      employeeId={value?.employeeId}
      token={value?.token}
      onSubmit={v => {
        setFormValues(v);
        if (setNamespace) {
          setNamespace(v.namespace);
        }
        if (setSelectedCluster) {
          setSelectedCluster(v.cluster);
        }
        if (setAvailableNamespaces && availableNamespaces) {
          setAvailableNamespaces([v.namespace, ...availableNamespaces]);
        }
        if (onChange) {
          onChange(v.namespace);
        }
      }}
    />
  ) : (
    <SubmittedCreateForm formValues={formValues} onResetForm={() => setFormValues(null)} />
  );
};
