/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  AnyApiFactory,
  configApiRef,
  createApiFactory,
  discoveryApiRef,
  githubAuthApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';
import { ScmAuth, ScmIntegrationsApi, scmIntegrationsApiRef } from '@backstage/integration-react';
import { ArgoCDApiClient, argoCDApiRef } from '@roadiehq/backstage-plugin-argo-cd';
import {
  restClientRef,
  aaPingSSOAuthApiRef,
  apis as devkitApis,
  ObserveServiceApiRef,
  Squad360ServiceApiRef,
  CatalogCustomizationsRecordStoreServiceRef,
} from '@runway/devkit';
import {
  ADOService,
  adoServiceApiRef,
  CloudElevatedAccessService,
  cloudElevatedAccessServiceApiRef,
  DeaService,
  deaServiceApiRef,
  ghecInviteFormApiRef,
  GhecRepoAdmins,
  ghecRepoAdminsFormApiRef,
  GhecService,
  PFAdminApiService,
  pfAdminServiceApiRef,
  SurveysAccessService,
  surveysServiceApiRef,
  VdiDesktopService,
  vdiDesktopServiceApiRef,
  VdiPoolListService,
  vdiPoolListServiceApiRef,
} from '@runway/plugin-access-control';
import {
  ApigeeProjectListQueryApiRef,
  ApigeeProjectsList,
  ApimBackendService,
  ApimBackendServiceApiRef,
} from '@runway/plugin-apigee-ui';
import {
  ArgoCDService,
  ArgoCDServiceApiRef,
  CatalogProjectsService,
  catalogProjectsServiceApiRef,
} from '@runway/plugin-catalog-customizations';
import { AzVMStartStopService, azVMStartStopServiceRef } from '@runway/plugin-cost-management';
import { EMFTBackendService, EMFTBackendServiceApiRef } from '@runway/plugin-emft-ui';
import {
  CreateVmOnPremApiRef,
  RebootServerService,
  rebootServerServiceApiRef,
  ReqCreateExtnLinuxFS,
  ReqCreateExtnLinuxFSApiRef,
  ResetPasswordService,
  resetPasswordServiceApiRef,
  VmBuildService,
  VmDecomService,
  vmDecomServiceApiRef,
  PatchNowService,
  patchNowServiceApiRef,
  VmService,
  vmServiceApiRef,
  VMComplianceService,
  vmComplianceApiRef,
  VmDecomDisplayService,
  vmDecomDisplayApiRef,
  cloudsmithServiceApiRef,
  CloudsmithService,
} from '@runway/plugin-infrastructure';
import { OrionService, orionServiceApiRef } from '@runway/plugin-orion-auto';
import { TowerDataService, towerDataServiceApiRef } from '@runway/plugin-tower-api';
import { Tracker, trackerApiRef } from './tracker';

import { OrionRegistrationService, orionRegistrationServiceApiRef } from '@runway/plugin-orion-ui';
import { SwaggerService, SwaggerServiceApiRef } from '@runway/plugin-swagger';
import { DiffServiceApiRef, DiffService } from '@runway/plugin-diff-tool';
import { CopilotDashboardService, dashServiceApiRef } from '@runway/plugin-dash';

export const apis: AnyApiFactory[] = [
  ...devkitApis,
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  ScmAuth.createDefaultApiFactory(),
  createApiFactory({
    api: argoCDApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      configApi: configApiRef,
      identityApi: identityApiRef,
    },
    factory: ({ discoveryApi, configApi, identityApi }) =>
      new ArgoCDApiClient({
        discoveryApi: discoveryApi,
        backendBaseUrl: configApi.getString('backend.baseUrl'),
        searchInstances: configApi
          .getConfigArray('argocd.appLocatorMethods')
          .some(element => element.getString('type') === 'config'),
        identityApi,
        useNamespacedApps: false,
      }),
  }),
  createApiFactory({
    api: ArgoCDServiceApiRef,
    deps: {
      configApi: configApiRef,
      identityApi: identityApiRef,
      catalogCustomizationRecordStoreServiceApi: CatalogCustomizationsRecordStoreServiceRef,
    },
    factory: ({ configApi, identityApi, catalogCustomizationRecordStoreServiceApi }) =>
      new ArgoCDService(configApi.getString('backend.baseUrl'), identityApi, catalogCustomizationRecordStoreServiceApi),
  }),
  createApiFactory({
    api: ApimBackendServiceApiRef,
    deps: {
      configApi: configApiRef,
      githubAuthApi: githubAuthApiRef,
      squad360Api: Squad360ServiceApiRef,
      oAuthApi: aaPingSSOAuthApiRef,
    },
    factory: ({ configApi, githubAuthApi, squad360Api, oAuthApi }) =>
      new ApimBackendService(
        configApi.getString(`github.ghec.url`),
        configApi.getString(`apigee.backendUrl`),
        githubAuthApi,
        squad360Api,
        oAuthApi,
      ),
  }),
  createApiFactory({
    api: ApigeeProjectListQueryApiRef,
    deps: {
      configApi: configApiRef,
      githubAuthApi: githubAuthApiRef,
    },
    factory: ({ configApi, githubAuthApi }) =>
      new ApigeeProjectsList(configApi.getString(`github.ghec.graphUrl`), githubAuthApi),
  }),
  createApiFactory({
    api: towerDataServiceApiRef,
    deps: {
      configApi: configApiRef,
      identityApi: identityApiRef,
      restClient: restClientRef,
    },
    factory: ({ configApi, identityApi, restClient }) =>
      new TowerDataService(configApi.getString('backend.baseUrl'), identityApi, restClient),
  }),
  createApiFactory({
    api: deaServiceApiRef,
    deps: {
      configApi: configApiRef,
      identityApi: identityApiRef,
    },
    factory: ({ configApi, identityApi }) => new DeaService(configApi.getString('backend.baseUrl'), identityApi),
  }),
  createApiFactory({
    api: adoServiceApiRef,
    deps: {
      configApi: configApiRef,
      identityApi: identityApiRef,
    },
    factory: ({ configApi, identityApi }) => new ADOService(configApi.getString('backend.baseUrl'), identityApi),
  }),
  createApiFactory({
    api: CreateVmOnPremApiRef,
    deps: {
      configApi: configApiRef,
      identityApi: identityApiRef,
      oAuthApi: aaPingSSOAuthApiRef,
    },
    factory: ({ configApi, identityApi, oAuthApi }) =>
      new VmBuildService(
        configApi.getString('vmManagement.myVmUrl'),
        configApi.getString('backend.baseUrl'),
        identityApi,
        oAuthApi,
      ),
  }),
  createApiFactory({
    api: ReqCreateExtnLinuxFSApiRef,
    deps: {
      configApi: configApiRef,
      identityApi: identityApiRef,
    },
    factory: ({ configApi, identityApi }) =>
      new ReqCreateExtnLinuxFS(configApi.getString('vmManagement.myVmUrl'), identityApi),
  }),
  createApiFactory({
    api: catalogProjectsServiceApiRef,
    deps: {
      configApi: configApiRef,
      githubAuthApi: githubAuthApiRef,
    },
    factory: ({ configApi, githubAuthApi }) =>
      new CatalogProjectsService(githubAuthApi, configApi.getString(`github.ghec.apiUrl`)),
  }),
  createApiFactory({
    api: trackerApiRef,
    deps: {
      oAuthApi: aaPingSSOAuthApiRef,
      observeServiceApi: ObserveServiceApiRef,
    },
    factory: ({ oAuthApi, observeServiceApi }) => new Tracker(oAuthApi, observeServiceApi),
  }),
  createApiFactory({
    api: DiffServiceApiRef,
    deps: {
      configApi: configApiRef,
      githubAuthApi: githubAuthApiRef,
      identityApi: identityApiRef,
    },
    factory: ({ githubAuthApi }) => new DiffService(githubAuthApi),
  }),
  createApiFactory({
    api: ghecInviteFormApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => new GhecService(configApi.getString('ghecAccessService.url')),
  }),
  createApiFactory({
    api: ghecRepoAdminsFormApiRef,
    deps: {
      configApi: configApiRef,
      oAuthApi: aaPingSSOAuthApiRef,
    },
    factory: ({ configApi, oAuthApi }) => new GhecRepoAdmins(configApi.getString('repoAdmins.url'), oAuthApi),
  }),
  createApiFactory({
    api: cloudElevatedAccessServiceApiRef,
    deps: {
      configApi: configApiRef,
      identityApi: identityApiRef,
      restClientApi: restClientRef,
    },
    factory: ({ configApi, identityApi, restClientApi }) =>
      new CloudElevatedAccessService(configApi.getString('backend.baseUrl'), identityApi, restClientApi),
  }),
  createApiFactory({
    api: resetPasswordServiceApiRef,
    deps: {
      configApi: configApiRef,
      oAuthApi: aaPingSSOAuthApiRef,
    },
    factory: ({ configApi, oAuthApi }) =>
      new ResetPasswordService(oAuthApi, configApi.getString('vmManagement.myVmUrl')),
  }),
  createApiFactory({
    api: vmServiceApiRef,
    deps: {
      configApi: configApiRef,
      oAuthApi: aaPingSSOAuthApiRef,
    },
    factory: ({ configApi, oAuthApi }) => new VmService(oAuthApi, configApi.getString('findMyServer.url')),
  }),
  createApiFactory({
    api: vmComplianceApiRef,
    deps: {
      configApi: configApiRef,
      oAuthApi: aaPingSSOAuthApiRef,
    },
    factory: ({ configApi, oAuthApi }) => new VMComplianceService(oAuthApi, configApi.getString('caasService.url')),
  }),
  createApiFactory({
    api: vmDecomServiceApiRef,
    deps: {
      configApi: configApiRef,
      oAuthApi: aaPingSSOAuthApiRef,
      identityApi: identityApiRef,
    },
    factory: ({ configApi, oAuthApi, identityApi }) =>
      new VmDecomService(
        oAuthApi,
        identityApi,
        configApi.getString('vmManagement.myVmUrl'),
        configApi.getString('backend.baseUrl'),
      ),
  }),
  createApiFactory({
    api: patchNowServiceApiRef,
    deps: {
      configApi: configApiRef,
      oAuthApi: aaPingSSOAuthApiRef,
      identityApi: identityApiRef,
    },
    factory: ({ configApi, oAuthApi, identityApi }) =>
      new PatchNowService(
        oAuthApi,
        identityApi,
        configApi.getString('vmManagement.myVmUrl'),
        configApi.getString('backend.baseUrl'),
      ),
  }),
  createApiFactory({
    api: vmDecomDisplayApiRef,
    deps: {
      configApi: configApiRef,
      oAuthApi: aaPingSSOAuthApiRef,
    },
    factory: ({ oAuthApi, configApi }) =>
      new VmDecomDisplayService(oAuthApi, configApi.getString('vmManagement.myVmUrl')),
  }),
  createApiFactory({
    api: rebootServerServiceApiRef,
    deps: {
      configApi: configApiRef,
      oAuthApi: aaPingSSOAuthApiRef,
    },
    factory: ({ configApi, oAuthApi }) =>
      new RebootServerService(oAuthApi, configApi.getString('vmManagement.myVmUrl')),
  }),
  createApiFactory({
    api: vdiDesktopServiceApiRef,
    deps: {
      configApi: configApiRef,
      oAuthApi: aaPingSSOAuthApiRef,
    },
    factory: ({ configApi, oAuthApi }) => new VdiDesktopService(oAuthApi, configApi.getString('virtualDesktop.url')),
  }),
  createApiFactory({
    api: vdiPoolListServiceApiRef,
    deps: {
      configApi: configApiRef,
      oAuthApi: aaPingSSOAuthApiRef,
    },
    factory: ({ configApi, oAuthApi }) => new VdiPoolListService(oAuthApi, configApi.getString('virtualDesktop.url')),
  }),
  createApiFactory({
    api: EMFTBackendServiceApiRef,
    deps: {
      configApi: configApiRef,
      oAuthApi: aaPingSSOAuthApiRef,
    },
    factory: ({ configApi, oAuthApi }) => new EMFTBackendService(oAuthApi, configApi.getString('emftService.url')),
  }),
  createApiFactory({
    api: orionServiceApiRef,
    deps: {
      configApi: configApiRef,
      identityApi: identityApiRef,
    },
    factory: ({ configApi, identityApi }) => new OrionService(configApi.getString('backend.baseUrl'), identityApi),
  }),
  createApiFactory({
    api: surveysServiceApiRef,
    deps: {
      configApi: configApiRef,
      identityApi: identityApiRef,
    },
    factory: ({ configApi, identityApi }) =>
      new SurveysAccessService(
        configApi.getString('backend.baseUrl'),
        {
          prod: configApi.getString('surveys.prod.appUrl'),
          non_prod: configApi.getString('surveys.non_prod.appUrl'),
        },
        identityApi,
      ),
  }),
  createApiFactory({
    api: orionRegistrationServiceApiRef,
    deps: {
      configApi: configApiRef,
      oAuthApi: aaPingSSOAuthApiRef,
      identityApi: identityApiRef,
    },
    factory: ({ configApi, oAuthApi, identityApi }) =>
      new OrionRegistrationService(
        configApi.getString('backend.baseUrl'),
        configApi.getString('amService.url'),
        identityApi,
        oAuthApi,
      ),
  }),
  createApiFactory({
    api: pfAdminServiceApiRef,
    deps: {
      configApi: configApiRef,
      oAuthApi: aaPingSSOAuthApiRef,
    },
    factory: ({ configApi, oAuthApi }) => new PFAdminApiService(configApi.get('pfadmin'), oAuthApi),
  }),
  createApiFactory({
    api: azVMStartStopServiceRef,
    deps: {
      configApi: configApiRef,
      oAuthApi: aaPingSSOAuthApiRef,
      identityApi: identityApiRef,
    },
    factory: ({ configApi, oAuthApi, identityApi }) =>
      new AzVMStartStopService(oAuthApi, configApi.get('azVMStartStopService.url'), identityApi),
  }),
  createApiFactory({
    api: SwaggerServiceApiRef,
    deps: {
      configApi: configApiRef,
      identityApi: identityApiRef,
    },
    factory: ({ configApi, identityApi }) => new SwaggerService(configApi.get('backend.baseUrl'), identityApi),
  }),
  createApiFactory({
    api: dashServiceApiRef,
    deps: {
      configApi: configApiRef,
      identityApi: identityApiRef,
      restClient: restClientRef,
    },
    factory: ({ configApi, identityApi, restClient }) =>
      new CopilotDashboardService(configApi.getString('backend.baseUrl'), identityApi, restClient),
  }),
  createApiFactory({
    api: cloudsmithServiceApiRef,
    deps: {
      configApi: configApiRef,
      identityApi: identityApiRef,
      restClient: restClientRef,
    },
    factory: ({ configApi, identityApi, restClient }) =>
      new CloudsmithService(configApi.getString('backend.baseUrl'), identityApi, restClient),
  }),
];
