import React, { useState } from 'react';
import { Fab, IconButton } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import SlackLogo from './SlackLogo.svg';
import CloseIcon from '@mui/icons-material/Close';

export const ChatButton = () => {
  const location = useLocation();
  const [showButton, setShowButton] = useState(true);

  const isTemplatePath = location.pathname.startsWith('/create/');

  if (showButton) {
    return (
      <div className="chatButton">
        <IconButton
          className="iconClose"
          style={{
            marginBottom: isTemplatePath ? '130px' : '0px',
          }}
          aria-label="closeIcon"
          size="small"
          onClick={() => setShowButton(false)}
        >
          <CloseIcon style={{ color: '#3D659D' }} />
        </IconButton>
        <Fab
          className="iconDiv"
          style={{
            marginBottom: isTemplatePath ? '85px' : '30px',
          }}
          aria-label="slack"
          href="https://americanairlines.slack.com/archives/C0112F1JKNG"
          target="_blank"
        >
          <img alt="slackLogo" src={SlackLogo} height="47px" width="47px" />
          <p className="iconText">Chat with us on Slack</p>
        </Fab>
      </div>
    );
  }
  return <></>;
};
export default ChatButton;
