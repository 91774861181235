import React, { useState } from 'react';
import {
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  Typography,
  makeStyles,
  createStyles,
  Theme,
  TextField,
} from '@material-ui/core';
import { useTheme as useMuiTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    radioGroup: {
      flexDirection: 'row',
    },
    typography: {
      color: theme.palette.text.secondary,
      padding: '8px 16px',
      backgroundColor: theme.palette.background.paper,
      borderRadius: '4px',
      boxShadow: theme.shadows[1],
    },
    alert: {
      marginTop: '30px',
      marginLeft: '10px',
      marginRight: '10px',
      width: '100%',
    },
    alertLink: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
  }),
);

export const HashicorpVault = (props: {
  vaultNamespace: string;
  vaultPath: string;
  onChange: (vaultInfo: { vaultNamespace: string; vaultPath: string }) => void;
}) => {
  const { vaultNamespace, vaultPath, onChange } = props;

  const [useVault, setUseVault] = useState(false);
  const [namespace, setNamespace] = useState(vaultNamespace);
  const [path, setPath] = useState(vaultPath);

  const handleVaultOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Update the useVault state depending on if the yes or no options are selected
    setUseVault(event.target.value === 'true');

    // If the user selects no, clear the namespace and path
    if (event.target.value === 'false') {
      setNamespace('');
      setPath('');
      onChange({ vaultNamespace: '', vaultPath: '' });
    }
  };

  const handleNamespaceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNamespace(event.target.value);
    onChange({ vaultNamespace: event.target.value, vaultPath: path });
  };

  const handlePathChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPath(event.target.value);
    onChange({ vaultNamespace: namespace, vaultPath: event.target.value });
  };

  const theme = useMuiTheme();
  const classes = useStyles(theme);

  return (
    <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
      <Typography aria-label="rw-info" className={classes.typography}>
        Would you like to use Hashicorp Vault to store secrets?
      </Typography>
      <RadioGroup
        className={classes.radioGroup}
        onChange={handleVaultOptionChange}
        aria-label="use-hashicorp-vault"
        name="option"
        value={useVault}
      >
        <FormControlLabel value control={<Radio />} label="Yes" />
        <FormControlLabel value={false} control={<Radio />} label="No" />
      </RadioGroup>
      {useVault && (
        <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
          <Grid item md={4}>
            <TextField
              id="hashicorp-vault-namespace"
              label="Hashicorp Vault Namespace"
              type="text"
              style={{ width: '100%' }}
              variant="outlined"
              value={namespace}
              onChange={handleNamespaceChange}
              placeholder="Enter the Hashicorp Vault namespace"
            />
          </Grid>
          <Grid item md={4}>
            <TextField
              id="hashicorp-vault-path"
              label="Hashicorp Vault Path"
              type="text"
              style={{ width: '100%' }}
              variant="outlined"
              value={path}
              onChange={handlePathChange}
              placeholder="Enter the path to stored secrets"
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
