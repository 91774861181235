import { createApiRef, IdentityApi } from '@backstage/core-plugin-api';
import { RestClientApi } from '@runway/devkit-common';

export interface CloudsmithServiceApi {
  getAllServiceAccounts(): Promise<any>;
}

export const cloudsmithServiceApiRef = createApiRef<CloudsmithServiceApi>({
  id: 'plugin.infrastructure.cloudsmith',
});

export default class CloudsmithService implements CloudsmithServiceApi {
  constructor(
    private backendUrl: string,
    private identityApi: IdentityApi,
    private restClient: RestClientApi,
  ) {}

  getAllServiceAccounts = async () => {
    const { token } = await this.identityApi.getCredentials();
    const res = await this.restClient.get(`${this.backendUrl}/api/cloudsmith/service-accounts`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (res.status !== 200) {
      throw new Error('Failed to get data');
    }

    return res.data;
  };
}
