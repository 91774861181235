import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import {
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useState } from 'react';
import InfoIcon from '@material-ui/icons/Info';
import { SecretVaultServiceApiRef } from '../../services';
import { useApi } from '@backstage/core-plugin-api';
import { useAsync } from 'react-use';

export type ReturnedData = {
  namespace: string | undefined;
};

const NoPaddingTooltip = withStyles({
  tooltip: {
    padding: 0,
    margin: 5,
  },
})(Tooltip);

const useStyles = makeStyles(() => ({
  inputLabel: {
    transform: 'translate(12px, 20px) scale(1)',
    '&[data-shrink=true]': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
  },
}));

const NamespaceInfoToolTip = () => (
  <NoPaddingTooltip
    title={
      <Alert severity="info">
        Only users with admin access to that particular namespace can manage the namespaces{' '}
      </Alert>
    }
  >
    <IconButton color="primary" size="small" aria-label="info-button">
      <InfoIcon />
    </IconButton>
  </NoPaddingTooltip>
);

export const SecretVaultNamespaceForm = ({ onChange, formContext }: FieldExtensionComponentProps<ReturnedData>) => {
  const shortNameValue = formContext?.formData?.cmdbAppShortName?.shortName ?? undefined;
  const classes = useStyles();
  const secretVaultApi = useApi(SecretVaultServiceApiRef);
  const [namespace, setNamespace] = useState<string>('');
  const [namespaceOption, setNamespaceOptions] = useState<{ value: string; disabled: boolean }[]>([]);

  const { loading, error } = useAsync(async () => {
    if (!shortNameValue) return;

    const responsenamepsaces = await secretVaultApi.getNameSpacesFromShortName(shortNameValue);
    const trimmedNamespaces = responsenamepsaces.reduce(
      (acc, { namespace_name, admin }) => {
        if (admin) {
          return [...acc, { value: namespace_name.replace(/\//g, ''), disabled: false }];
        }
        return [...acc, { value: namespace_name.replace(/\//g, ''), disabled: true }];
      },
      [] as { value: string; disabled: boolean }[],
    );
    setNamespaceOptions(trimmedNamespaces);
  }, [shortNameValue, secretVaultApi]);

  const handleNamespaceChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newNamespace = event.target.value as string;
    setNamespace(newNamespace);
    onChange({ namespace: newNamespace });
  };

  return (
    <FormControl style={{ margin: 1, minWidth: 360, display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
      <InputLabel id="demo-simple-select-disabled-label" className={classes.inputLabel}>
        Vault Namespace
      </InputLabel>
      <Select
        labelId="demo-simple-select-disabled-label"
        id="demo-simple-select-disabled"
        value={namespace}
        onChange={handleNamespaceChange}
        style={{ width: '300px' }}
        label="Namespace text"
        variant="outlined"
        required
        disabled={loading || namespaceOption.length === 0}
      >
        {' '}
        {namespaceOption.map((option, index) => (
          <MenuItem key={index} value={option.value} disabled={option.disabled}>
            {option.value}
          </MenuItem>
        ))}
      </Select>
      {!loading && <NamespaceInfoToolTip />}
      {loading && (
        <div style={{ marginLeft: 10 }}>
          <CircularProgress />
        </div>
      )}
      {error && <p style={{ color: 'rgb(244, 67, 54)' }}>Failed to fetch vault namespaces</p>}
    </FormControl>
  );
};
