import React, { useEffect, useState } from 'react';
import { Progress, Table, TableColumn } from '@backstage/core-components';
import moment from 'moment';
import { useEntity } from '@backstage/plugin-catalog-react';
import { ContainerImagesEntity } from '@runway/plugin-container-images-common';
import { Box, Chip, Divider, Grid, IconButton, Snackbar } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { compareVersions } from 'compare-versions';

type Rows = {
  name: string;
  tag: string;
  pull: string;
  updated: string;
  digest: string;
};

type Tag = {
  name: string;
  digest: string;
  id: string | undefined;
  lastUpdated: string;
};

type Tags = {
  digest: string;
  tags: Tag[];
};

const CopyToClipboardButton = (data: any) => {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(data.pullString);
  };

  return (
    <>
      <Box onClick={handleClick}>
        <IconButton size="small">
          <FileCopyIcon fontSize="small" />
        </IconButton>
        {data.pullString}
      </Box>
      <Snackbar open={open} onClose={() => setOpen(false)} autoHideDuration={2000} message="Copied to clipboard" />
    </>
  );
};

const compareTags = (c: Tag, d: Tag) => {
  const cname = c.name.replace('-dev', '').replace(/.*-/gi, '');
  const dname = d.name.replace('-dev', '').replace(/.*-/gi, '');
  try {
    return compareVersions(cname, dname);
  } catch {
    return -1;
  }
};

const dedupe = (arr: string[] | undefined): string[] => {
  return [...new Set(arr)];
};

const majorVersions = (tags: Tags[]) => {
  const allMajorVersions = tags?.map(item => {
    return item.tags[0].name.replace('-dev', '').replace(/.*-/gi, '').split('.')[0];
  });
  return dedupe(allMajorVersions);
};

export const ContainerTags = () => {
  const { entity } = useEntity<ContainerImagesEntity>();

  const [tags, setTags] = useState<Tags[]>([]);

  const CustomFilter = (props: { onFilterChanged: (rowId: any, value: string) => void; columnDef: any }) => {
    const handleClick = (version: string) => {
      props.onFilterChanged(props.columnDef.tableData.id, version);
    };

    const majorVersionsDeduped = majorVersions(tags);

    return (
      <>
        {majorVersionsDeduped.length > 1 && (
          <Grid container alignItems="center">
            {majorVersionsDeduped.map(version => (
              <Chip label={version} clickable onClick={() => handleClick(version)} />
            ))}
            <Grid item xs>
              <Divider orientation="vertical" flexItem />
            </Grid>
            <Chip label="Dev" clickable onClick={() => handleClick('dev')} />
            <Chip label="Prod" clickable onClick={() => handleClick('prod')} />
            <Grid item xs>
              <Divider orientation="vertical" flexItem />
            </Grid>
            <Chip color="secondary" label="Reset" clickable onClick={() => handleClick('')} />
          </Grid>
        )}
      </>
    );
  };

  const columns: TableColumn<Rows>[] = [
    {
      title: 'Name',
      field: 'name',
      width: '15%',
      hidden: true,
    },
    {
      title: 'Tag',
      field: 'tag',
      width: '20%',
      filterComponent: CustomFilter,
      customFilterAndSearch: (filter, rowData) => {
        if (filter === 'prod') {
          return !rowData.tag.split(', ')[0].includes('-dev');
        } else if (filter === 'dev') {
          return rowData.tag.split(', ')[0].includes('-dev');
        }
        return rowData.tag.split(', ')[0].split('.')[0].includes(filter);
      },
      render: (rowData: Rows) => rowData.tag.split(', ').map(item => <Chip size="small" label={item} />),
    },
    {
      title: 'Type',
      field: 'type',
      width: '15%',
      hidden: true,
    },
    {
      title: 'Pull Value',
      field: 'pull',
      filtering: false,
      render: (rowData: Rows) => (
        <Grid container>
          <CopyToClipboardButton pullString={rowData.pull} />
        </Grid>
      ),
    },
    {
      title: 'Last Updated',
      field: 'updated',
      width: '10%',
      filtering: false,
      render: (rowData: Rows) => moment(rowData.updated).fromNow(),
    },
  ];

  useEffect(() => {
    const digests = new Map();

    for (const { digest, name, lastUpdated, id } of entity.spec.tags) {
      if (!digests.has(digest)) {
        digests.set(digest, { digest, tags: [] });
      }
      digests.get(digest).tags.push({ name, lastUpdated, id, digest });
    }
    const distinctDigests = [...digests.values()];
    distinctDigests
      .sort((a: Tags, b: Tags) => {
        b.tags.sort((c, d) => compareTags(c, d));
        a.tags.sort((c, d) => compareTags(c, d));

        // Ensure that `-dev` images are sorted below non-dev images
        const aName = a.tags[a.tags.length - 1].name;
        const bName = b.tags[b.tags.length - 1].name;
        const aNormalized = aName.includes('-dev') ? aName.replace('-dev', '.0') : aName.concat('.1');
        const bNormalized = bName.includes('-dev') ? bName.replace('-dev', '.0') : bName.concat('.1');

        try {
          return compareVersions(aNormalized.replace(/.*-/gi, ''), bNormalized.replace(/.*-/gi, ''));
        } catch {
          return 1;
        }
      })
      .reverse();

    setTags(distinctDigests);
  }, [entity]);

  return !tags ? (
    <Progress />
  ) : (
    <>
      Last Fetch: {moment(entity.spec.lastFetched).fromNow()}
      <Table
        options={{
          search: false,
          paging: false,
          sorting: false,
          showTitle: false,
          toolbar: false,
          detailPanelType: 'single',
          filtering: majorVersions(tags).length > 1,
        }}
        columns={columns}
        detailPanel={[
          {
            tooltip: 'Expand',
            render: rowData => {
              return (
                <Grid>
                  <Grid container>
                    <Grid item xs={1} />
                    <Grid item xs={11}>
                      <Table
                        title={entity.metadata.name}
                        options={{ search: false, paging: false, sorting: false, showTitle: false, toolbar: false }}
                        columns={columns}
                        data={rowData.rowData.tag.split(', ').map(item => {
                          return {
                            name: entity.metadata.name,
                            tag: item,
                            pull: `${entity.spec.location}/${entity.spec.registry}/${entity.metadata.name}:${item}@${rowData.rowData.digest}`,
                            updated: rowData.rowData.updated,
                            digest: rowData.rowData.digest,
                          };
                        })}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              );
            },
          },
        ]}
        data={tags.map(tag => {
          return {
            name: entity.metadata.name,
            tag: tag.tags.map(item => item.name).join(', '),
            pull: `${entity.spec.location}/${entity.spec.registry}/${entity.metadata.name}:${tag.tags[0].name}@${tag.digest}`,
            type: tag.tags.every(el => el.name.includes('-dev')) ? 'dev' : 'prod',
            updated: tag.tags[tag.tags.length - 1].lastUpdated,
            digest: tag.digest,
          };
        })}
      />
    </>
  );
};
