import { createBaseThemeOptions, createUnifiedTheme, palettes, UnifiedThemeProvider } from '@backstage/theme';
import React from 'react';
import { AppTheme } from '@backstage/core-plugin-api';
import { pageCssOverrides } from './pageCssOverrides';
import { CssBaseline } from '@material-ui/core';

/**
 * These theme customizations will be set globally for Backstage and Material-UI.
 *
 * There are a couple of things to keep in mind that will make the theme work best
 * for everyone.
 *
 * Try to use a specific component override before using `MuiCssBaseline`, this
 * makes sure that the styling will only apply to that component and not have
 * any effects on other elements.
 *
 * When doing custom CSS please avoid using `!important`. It will get your CSS
 * working, but doesn't let plugin authors customize the component to work best
 * for their plugin/page. It should be used rarely, and a comment should be
 * left for the reason why it was used.
 *
 * There are some components that have higher specificity because of how
 * Material UI has created their components. If you run into this case where
 * your custom styles in here are being overridden by the normal styles, opt for
 * doubling up the selector instead of using `!important`. You can do this by
 * using `&&` as a key in the theme. There are some examples below.
 *
 * Reference: https://www.w3schools.com/css/css_specificity.asp
 */

const DEFAULT_FONT_FAMILY =
  "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif";

export const RunwayLightTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.light,
      primary: {
        main: 'hsl(210, 50%, 45%)',
        light: undefined,
        dark: undefined,
        contrastText: undefined,
      },
      secondary: {
        main: 'hsl(30, 65%, 52%)',
        light: undefined,
        dark: undefined,
        contrastText: undefined,
      },
      border: 'hsl(210, 30%, 30%)',
      navigation: {
        ...palettes.light.navigation,
        background: 'hsl(210, 41%, 21%)',
        color: 'hsl(210, 20%, 70%)',
        selectedColor: 'hsl(210, 30%, 90%)',
        indicator: 'hsl(210, 50%, 45%)',
        navItem: {
          hoverBackground: 'hsl(210, 66%, 27%)',
        },
        submenu: {
          background: 'hsl(210, 66%, 27%)',
        },
      },
      text: {
        primary: '#000',
      },
    },
    fontFamily: DEFAULT_FONT_FAMILY,
  }),
  components: {
    BackstageHeader: {
      styleOverrides: {
        header: {
          backgroundImage: 'unset',
          boxShadow: 'unset',
        },
        title: {
          color: '#000',
        },
        subtitle: {
          color: '#000',
        },
        type: {
          color: '#000',
        },
        breadcrumb: {
          color: '#000',
        },
        rightItemsBox: {
          '& button[aria-label="more"]': {
            color: '#000',
          },
        },
      },
    },
    BackstageHeaderLabel: {
      styleOverrides: {
        label: {
          color: '#000',
        },
        value: {
          color: '#000',
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        ol: {
          color: 'black',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: 'hsl(210, 55%, 85%)',

          '&&:hover': {
            backgroundColor: 'hsl(210, 50%, 80%)',
          },
        },
        label: {
          color: 'hsl(213, 30%, 35%)',
        },
        avatar: {
          '&&': {
            color: 'hsl(210, 25%, 50%)',
            backgroundColor: 'transparent',
          },
        },
        colorPrimary: {
          backgroundColor: 'hsl(210, 55%, 85%)',

          '&&:hover': {
            backgroundColor: 'hsl(210, 50%, 80%)',
          },
        },
        avatarColorPrimary: {
          '&&': {
            color: 'hsl(207, 80%, 50%)',
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '@global': {
          ...pageCssOverrides,
        },
        a: {
          color: 'inherit',
          textDecoration: 'none',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: '#db3131',
          '&$error': {
            color: '#db3131',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'hsl(210, 20%, 95%)',
          },
        },
        iconWrapper: {
          color: 'hsl(210, 25%, 25%)',
        },
        wrapper: {
          display: 'flex',
          flexDirection: 'row-reverse',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: 'hsl(210, 50%, 45%)',
      },
    },
  } as any,
});

export const RunwayDarkTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.dark,
      mode: 'dark',
      primary: {
        main: 'hsl(210, 50%, 45%)',
        light: undefined,
        dark: undefined,
        contrastText: undefined,
      },
      secondary: {
        main: 'hsl(30, 75%, 60%)',
        light: undefined,
        dark: undefined,
        contrastText: undefined,
      },
      background: {
        default: 'hsl(210, 25%, 5%)',
        paper: 'hsl(210, 30%, 12%)',
      },
      border: 'hsl(210, 30%, 20%)',
      navigation: {
        ...palettes.dark.navigation,
        background: 'hsl(210, 30%, 9%)',
        color: 'hsl(210, 20%, 70%)',
        selectedColor: 'hsl(210, 30%, 90%)',
        indicator: 'hsl(210, 50%, 45%)',
        navItem: {
          hoverBackground: 'hsl(210, 30%, 12%)',
        },
        submenu: {
          background: 'hsl(210, 30%, 12%)',
        },
      },
      text: {
        primary: '#fff',
      },
    },
    fontFamily: DEFAULT_FONT_FAMILY,
  }),
  components: {
    BackstageHeader: {
      styleOverrides: {
        header: {
          backgroundImage: 'unset',
          boxShadow: 'unset',
        },
        title: {
          color: '#fff',
        },
        subtitle: {
          color: '#fff',
        },
        type: {
          color: '#fff',
        },
        breadcrumb: {
          color: '#fff',
        },
      },
    },
    BackstageHeaderLabel: {
      styleOverrides: {
        label: {
          color: '#fff',
        },
        value: {
          color: '#fff',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: 'hsl(210, 39%, 22%)',
          margin: 4,

          '&&:hover': {
            backgroundColor: 'hsl(210, 39%, 26%)',
          },
        },
        label: {
          color: 'hsl(215, 80%, 85%)',
        },
        avatar: {
          '&&': {
            backgroundColor: 'transparent',
          },
        },
        colorPrimary: {
          backgroundColor: 'hsl(210, 39%, 22%)',

          '&&:hover': {
            backgroundColor: 'hsl(210, 39%, 26%)',
          },
        },
        avatarColorPrimary: {
          '&&': {
            color: 'hsl(205, 80%, 50%)',
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '@global': {
          ...pageCssOverrides,
        },
        a: {
          color: 'inherit',
          textDecoration: 'none',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: '#db3131',
          '&$error': {
            color: '#db3131',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'hsl(210, 30%, 14%)',
          },
        },
        iconWrapper: {
          color: 'hsl(210, 25%, 85%)',
        },
        wrapper: {
          display: 'flex',
          flexDirection: 'row-reverse',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: 'hsl(210, 50%, 45%)',
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: 'hsl(210, 30%, 14%)',
          },
        },
      },
    },
  } as any,
});

export const RunwayThemes: AppTheme[] = [
  {
    id: 'light',
    title: 'Light',
    variant: 'light',
    Provider: (props: any) => (
      <UnifiedThemeProvider theme={RunwayLightTheme} noCssBaseline>
        <CssBaseline />
        {props.children}
      </UnifiedThemeProvider>
    ),
  },
  {
    id: 'dark',
    title: 'Dark',
    variant: 'dark',
    Provider: (props: any) => (
      <UnifiedThemeProvider theme={RunwayDarkTheme} noCssBaseline>
        <CssBaseline />
        {props.children}
      </UnifiedThemeProvider>
    ),
  },
];
